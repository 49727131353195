import React from "react"
import Layout from "../components/layout"
import { Row, Shim, PageTitle } from "../components/row"

export default () => (
  <Layout>
    <Row>
      <PageTitle>Thank you</PageTitle>
      <Shim
        style={{ fontSize: "28px", textAlign: "center", fontWeight: "200" }}
      >
        <p>
          Your submission has been received. You should hear from us in 1-2
          business days. If you submitted an order please be aware that{" "}
          <strong>your order is not confirmed until full payment</strong> has
          been made.
        </p>
        <p style={{ fontSize: "18px" }}>
          Thank you,
          <br />
          <strong>The Frosted Cakery team</strong>
        </p>
      </Shim>
    </Row>
  </Layout>
)
